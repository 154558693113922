<template>
  <div class="create-assessment">
    <template v-if="!type && !editId && !$route.params.id">
      <div class="type-select">
        <h3>Select an assessment type:</h3>
        <div class="types">
          <div @click="type = 'standard'">
            <StandardAssessmentIcon />
            <hr />
            <div class="label">
              <h6>Standard Assessment</h6>
              <ArrowIcon />
            </div>
          </div>
          <div @click="type = 'branching'">
            <BranchedAssessmentIcon />
            <hr />
            <div class="label">
              <h6>Branched Assessment</h6>
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <CreateAssessmentSection SectionTitle="Assessment Settings">
        <div v-for="setting in typeFilteredAssessmentSettings" :key="setting.id" class="setting">
          <div
            class="checkbox"
            @click.prevent="
              setting.checked = !setting.checked;
              handleSettingToggle(setting);
            "
          >
            <CheckboxActive v-if="setting.checked" />
            <CheckboxInactive v-else />
          </div>
          <span>{{ setting.text }}</span>
          <InfoIcon class="info" v-tooltip.right="{ content: `${setting.tooltip}` }" />
        </div>
      </CreateAssessmentSection>
      <CreateAssessmentSection SectionTitle="Details">
        <div v-for="detail in assessmentDetails" :key="detail.id" class="detail">
          <template v-if="!detail.hidden">
            <h6 class="title">{{ detail.title }}</h6>
            <input
              v-if="detail.id === 'title'"
              type="text"
              v-model="detail.value"
              placeholder="Title"
              :class="{ smaller: detail.id === 'overallTimeLimit' }"
            />
            <template v-else-if="detail.id === 'customInstructions'">
              <input
                type="text"
                v-model="detail.value"
                class="smaller"
                placeholder="Type your start screen instructions…"
                :maxlength="1000"
              />
            </template>
            <template v-else-if="detail.id === 'passingPercentage'">
              <input type="number" v-model="detail.value" min="0" class="smaller" placeholder="0" max="100" />
              <span class="minutes">%</span>
            </template>
            <template v-else-if="detail.id === 'overallTimeLimit'">
              <input
                type="number"
                v-model.number="detail.value"
                class="smaller"
                @keypress="isNumber($event)"
                pattern="[0-9]*"
                min="1"
              />
              <span class="minutes">minutes</span>
            </template>
            <template v-else>
              <span class="details-text">{{ detail.value }}</span>
            </template>
            <span class="description">{{ detail.description }}</span>
          </template>
        </div>
      </CreateAssessmentSection>
      <CreateAssessmentSection v-if="assessmentQuestions.length" SectionTitle="Questions">
        <div v-for="(question, questionIndex) in assessmentQuestions" :key="questionIndex" class="question">
          <div class="order">
            <h6 class="title">Order*</h6>
            <input
              v-if="!assessmentSettings[0].checked"
              type="number"
              v-model="question.order"
              min="1"
              :max="assessmentQuestions.length"
              :disabled="type === 'branching'"
            />
            <input v-else type="number" disabled />
          </div>
          <div class="questions-content">
            <div class="type">
              <h6 class="title">Question Type*</h6>
              <Multiselect
                v-model="question.type"
                :options="questionTypes"
                track-by="TYPE_ID"
                label="TYPE_NAME"
                placeholder="Select a question type"
                :show-labels="false"
                @select="changedQuestionType($event, questionIndex)"
              >
              </Multiselect>
            </div>
            <!-- Individual Time Limit -->
            <div v-if="assessmentSettings[3].checked" class="individual-time-limit">
              <input
                type="number"
                v-model.number="question.individualTimeLimit"
                class="smaller"
                @keypress="isNumber($event)"
              />
              <span class="minutes">minutes</span>
              <span class="description">Set an individual time limit for this question.</span>
            </div>
            <!-- Question  -->
            <div class="prompt">
              <h6 class="title">Question*</h6>
              <input type="text" v-model="question.prompt" placeholder="Type your question..." />
              <span v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 5" class="description"
                >Include [blank] to denote the blank space in this question. To include multiple blanks for the user to
                fill in, denote each blank with [blank] and add another answer input below. You can include up to five
                blanks/answers.</span
              >
            </div>
            <!-- Image Attach, which is below the Prompt input for some reason on Hotspot and Drag And Drops -->
            <div
              v-if="
                (question.type && question.type.TYPE_ID && question.type.TYPE_ID === 6) || question.type.TYPE_ID === 7
              "
              class="image-attach"
            >
              <Dropzone
                type="hotspot"
                v-model="question.image"
                :acceptedFiles="'image/png, .png, image/jpg, .jpg, image/jpeg, .jpeg'"
                @accepted="imageAccepted($event, questionIndex)"
                @clear="clearImage(questionIndex)"
              />
            </div>
            <!-- Answers -->
            <div
              v-if="
                (question.type && question.type.TYPE_ID && question.type.TYPE_ID === 1) || question.type.TYPE_ID === 2
              "
              class="answers"
            >
              <h6 class="title">Answer Options*</h6>
              <span v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 1" class="description"
                >Multiple choice questions must have a minimum of two answer options. Check at least one option as the
                correct answer.</span
              >
              <span
                v-else-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 2"
                class="description"
                >Single choice questions must have a minimum of two answer options. Select one option as the correct
                answer.</span
              >
              <div v-for="(answer, answerIndex) in question.answers" :key="answer.id">
                <AssessmentAnswer
                  :answer="answer"
                  @answerToggled="answerToggled(answerIndex, questionIndex)"
                  @removeAnswer="removeAnswer(answerIndex, questionIndex)"
                  :answerIndex="answerIndex + 1"
                  :canRemove="question.answers.length > 1"
                />
              </div>
            </div>
            <div
              v-if="
                question.type && question.type.TYPE_ID && (question.type.TYPE_ID === 1 || question.type.TYPE_ID === 2)
              "
              class="add-option"
              @click="addAnswer(questionIndex)"
            >
              <PlusIcon />
              <span>Add option</span>
            </div>
            <!-- Matching -->
            <div v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 3" class="matching">
              <div v-for="(row, rowIndex) in question.matching" :key="rowIndex" class="row">
                <div class="left">
                  <h6 class="title">Match 1*</h6>
                  <input type="text" v-model="row.value1" :placeholder="`Match ${rowIndex + 1} item 1`" />
                </div>
                <div class="right">
                  <h6 class="title">Match 2*</h6>
                  <input type="text" v-model="row.value2" :placeholder="`Match ${rowIndex + 1} item 2`" />
                </div>
              </div>
            </div>
            <div
              v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 3"
              class="add-option"
              @click="addMatch(questionIndex)"
            >
              <PlusIcon />
              <span>Add new match</span>
            </div>
            <!-- Open Answer -->
            <div v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 4" class="open-answer">
              <h6 class="title"></h6>
              <input type="text" v-model="question.openAnswer" placeholder="Type your answer…" />
              <span v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 4" class="description"
                >Open answers are not case sensitive.</span
              >
            </div>
            <div
              v-else-if="question.type.TYPE_ID === 5 && question.blanks && question.blanks.length"
              class="open-answer fill-in-the-blank"
            >
              <div v-for="(blank, blankIndex) in question.blanks" :key="blankIndex">
                <h6 class="title">Blank {{ blankIndex + 1 }} Answer*</h6>
                <div class="blank-input">
                  <input v-model="blank.text" placeholder="Blank..." />
                  <div
                    class="right"
                    v-if="question.blanks.length > 1"
                    @click.prevent="question.blanks.splice(blankIndex, 1)"
                  >
                    <XIcon />
                    <span>Remove</span>
                  </div>
                </div>
                <span class="description">Fill-in-the-blank answers are not case sensitive.</span>
              </div>
              <div @click.prevent="question.blanks.push({ text: '' })" class="add-question">
                <PlusIcon />
                <span>Add answer</span>
              </div>
            </div>
            <!-- Builder -->
            <div v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 6" class="builder">
              <h6 class="title">Builder</h6>
              <div
                class="builder-inner"
                :id="`canvas-${questionIndex}`"
                :class="{ grey: !question.image && !question.mediaUrl }"
              >
                <div
                  @mouseup="hotspotMouseUp($event, questionIndex)"
                  @mousedown="hotspotMouseDown($event, questionIndex)"
                  class="canvas"
                  :class="{ fullHeight: question.image }"
                ></div>
                <img
                  v-if="question.image || question.mediaUrl"
                  :src="getSrc(question)"
                  :id="`image-${questionIndex}`"
                />
                <div
                  v-if="question.x1percent && question.y1percent && question.x2percent && question.y2percent"
                  class="builder-overlay"
                >
                  <div
                    class="overlay-piece"
                    id="top"
                    :style="`width: 100%; height: ${Math.min(
                      assessmentQuestions[questionIndex].y1percent,
                      assessmentQuestions[questionIndex].y2percent,
                    )}%`"
                  ></div>
                  <div
                    class="center-row"
                    :style="`height: ${Math.abs(
                      assessmentQuestions[questionIndex].y1percent - assessmentQuestions[questionIndex].y2percent,
                    )}%`"
                  >
                    <div
                      class="overlay-piece"
                      id="left"
                      :style="`width: ${Math.min(
                        assessmentQuestions[questionIndex].x1percent,
                        assessmentQuestions[questionIndex].x2percent,
                      )}%`"
                    ></div>
                    <div
                      class="hotspot"
                      :id="`hotspot-${questionIndex}`"
                      :style="`width: calc(${Math.abs(
                        assessmentQuestions[questionIndex].x1percent - assessmentQuestions[questionIndex].x2percent,
                      )}% - 2px)`"
                    ></div>
                    <div class="overlay-piece" id="right" :style="`flex: auto`"></div>
                  </div>
                  <div class="overlay-piece" id="bottom" :style="`flex: auto; width: 100%`"></div>
                </div>
              </div>
              <span class="description"
                >Select a background image and use your cursor to define the hotspot area users need to click within to
                answer this question correctly.</span
              >
            </div>
            <div v-else-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 7" class="builder">
              <h6 class="title">Builder</h6>
              <div
                class="builder-inner"
                :id="`canvas-${questionIndex}`"
                :class="{ grey: !question.image && !question.mediaUrl }"
              >
                <img
                  v-for="(draggableItem, draggableItemIndex) in question.draggableItems"
                  :key="draggableItemIndex"
                  :src="getSrc(draggableItem)"
                  :style="{
                    top: `${parseInt(draggableItem.y, 10)}px`,
                    left: `${parseInt(draggableItem.x, 10)}px`,
                    transform: `scale(${100 / question.canvasScaler}%)`,
                  }"
                  class="canvas-item"
                  :class="{ hidden: !draggableItem.scaledX || !draggableItem.scaledY }"
                />
                <img
                  v-if="question.image || question.mediaUrl"
                  @dragenter.prevent
                  @dragover.prevent
                  @drop="dragEnd($event)"
                  :src="getSrc(question)"
                  :id="`image-${questionIndex}`"
                  @load="getCanvasScales"
                />
              </div>
              <div class="draggable-items" :class="{ empty: question.draggableItems[0].image === null }">
                <div v-for="(item, itemIndex) in question.draggableItems" :key="itemIndex" class="draggable-item">
                  <img
                    draggable="true"
                    @dragstart="dragStart($event, item, itemIndex)"
                    v-if="item.image || item.mediaUrl"
                    :src="getSrc(item)"
                    :id="`${questionIndex}-${itemIndex}`"
                    :class="`draggable-item-${questionIndex}-${itemIndex}`"
                    :style="{ width: `${100 / question.canvasScaler}%` }"
                  />
                </div>
              </div>
              <span class="description"
                >Select a background image, specify the draggable items, and define the drop zones on the background
                image into which they should be dragged. Position the drop zone location by dragging it directly onto
                the background image. The Left and Top pixel values will automatically update as you move the object.
                When a user attempts this question, they will need to place the draggable items into these specified
                drop zones. Up to five draggable items can be uploaded. Any uploaded items that are not placed onto the
                background will be incorrect answer options.</span
              >
              <div class="draggable-options">
                <div
                  v-for="(draggableOption, draggableItemIndex) in question.draggableItems"
                  :key="draggableItemIndex"
                  class="option"
                >
                  <h6>Draggable Option {{ draggableItemIndex + 1 }}*</h6>
                  <div class="row">
                    <div class="left">
                      <Dropzone
                        type="hotspot"
                        :value="draggableOption.mediaUrl"
                        :acceptedFiles="'image/png, .png, image/jpg, .jpg, image/jpeg, .jpeg'"
                        @accepted="draggableImageAccepted($event, questionIndex, draggableItemIndex)"
                        @clear="clearDraggableImage(questionIndex, draggableItemIndex)"
                      />
                    </div>
                    <div class="right">
                      <input type="number" v-model="draggableOption.x" class="left" /><span>px</span>
                      <input type="number" v-model="draggableOption.y" class="right" /><span>px</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="question.type && question.type.TYPE_ID && question.type.TYPE_ID === 7"
                  class="add-option"
                  @click="addDraggableItem(questionIndex)"
                >
                  <PlusIcon />
                  <span>Add option</span>
                </div>
              </div>
            </div>
            <!-- Media -->
            <div class="media" v-if="question.type.TYPE_ID !== 6 && question.type.TYPE_ID !== 7">
              <div class="type">
                <h6>Upload Media</h6>
                <Multiselect
                  v-model="question.mediaType"
                  :options="mediaTypes"
                  placeholder="Select media type"
                  :show-labels="false"
                ></Multiselect>
                <div class="description">
                  Optional: Upload an image, video, or audio clip to be included with this question
                </div>
              </div>
              <!-- Image Attach -->
              <div v-if="question.mediaType === 'Image'" class="image-attach">
                <h6 class="title">Image</h6>
                <Dropzone
                  type="assessment"
                  :label="question.mediaUrl ? 'Upload Image' : 'New Image'"
                  :value="question.mediaUrl"
                  :fileName="getFileNameFromUrl(question.mediaUrl)"
                  :acceptedFiles="'image/png, .png, image/jpg, .jpg, image/jpeg, .jpeg'"
                  @accepted="imageAccepted($event, questionIndex)"
                  @clear="clearImage(questionIndex)"
                />
                <div
                  v-if="!question.mediaUrl || !question.mediaUrl.length || !question.mediaUrl.length === 0"
                  class="rules"
                >
                  File size limit: 10 MB. Accepted file types: JPEG, JPG, PNG
                </div>
              </div>
              <!-- Video Attach -->
              <div v-else-if="question.mediaType === 'Video'" class="image-attach">
                <h6 class="title">Video</h6>
                <Dropzone
                  type="assessment"
                  :label="question.mediaUrl ? 'Upload Video' : 'New Video'"
                  :value="question.mediaUrl"
                  :fileName="getFileNameFromUrl(question.mediaUrl)"
                  :acceptedFiles="'video/mp4, .mp4, image/mov, .mov'"
                  @accepted="imageAccepted($event, questionIndex)"
                  @clear="clearImage(questionIndex)"
                />
                <div
                  v-if="!question.mediaUrl || !question.mediaUrl.length || !question.mediaUrl.length === 0"
                  class="rules"
                >
                  File size limit: 10 MB. Accepted file types: MP4
                </div>
              </div>
              <!-- Audio Clip Attach -->
              <div v-else-if="question.mediaType === 'Audio Clip'" class="image-attach">
                <h6 class="title">Audio Clip</h6>
                <Dropzone
                  type="assessment"
                  :label="question.mediaUrl ? 'Upload Audio Clip' : 'New Audio Clip'"
                  :value="question.mediaUrl"
                  :fileName="getFileNameFromUrl(question.mediaUrl)"
                  :acceptedFiles="'audio/mp3, .mp3, audio/wav, .wav'"
                  @accepted="imageAccepted($event, questionIndex)"
                  @clear="clearImage(questionIndex)"
                />
                <div
                  v-if="!question.mediaUrl || !question.mediaUrl.length || !question.mediaUrl.length === 0"
                  class="rules"
                >
                  File size limit: 10 MB. Accepted file types: MP3
                </div>
              </div>
            </div>
            <div class="remediation">
              <h6 class="title">Remediation Text</h6>
              <div class="description">
                If added, users who answer this question incorrectly will see this remediation text in their results.
                You can also include a link that will follow the text.
              </div>
              <div class="description">
                Example: “Learn more about Volkswagen Battery Replacement & Inspection here:
                <a href="#" class="link">URL Title</a>“
              </div>
              <input
                v-model="question.remediationText"
                class="remediation_input"
                type="text"
                placeholder="Type your remediation text…"
              />
              <!-- TODO validate url -->
              <input
                v-model="question.remediationURL"
                class="remediation_input"
                :class="{ error: hasInvalidRemediationURL(question) }"
                type="text"
                placeholder="Remediation URL"
                @blur="validateRemediationURL(question)"
              />
              <span v-if="hasInvalidRemediationURL(question)" class="error-message"
                >Please enter a valid URL (must start with http:// or https://)</span
              >
              <input
                v-model="question.remediationURLTitle"
                class="remediation_input"
                type="text"
                placeholder="Remediation URL Title"
              />
            </div>
            <div class="bottom-row">
              <div class="left"></div>
              <div class="right" v-if="assessmentQuestions.length > 1">
                <XIcon />
                <span @click.prevent="removeQuestion(questionIndex)">Remove Question</span>
              </div>
            </div>
          </div>
        </div>
        <div @click.prevent="addQuestion" class="add-question">
          <PlusIcon />
          <span>Add question</span>
        </div>
      </CreateAssessmentSection>
      <CreateAssessmentSection
        v-if="type === 'branching'"
        SectionTitle="Branching Logic"
        SectionDescription="Create at least two questions to set up branching logic. Branched assessments will always begin with Question
          1. When creating branching logic, your branch destinations must be a higher numbered question than the parent
          question"
      >
        <div
          v-for="(question, branchingQuestionIndex) in branchingQuestions"
          :key="branchingQuestionIndex"
          class="branching-question"
        >
          <h6>Question {{ question.order }}</h6>
          <p>{{ question.prompt }}</p>
          <h6>{{ question.type.TPE_NAME }} question</h6>
          <p class="smallText">
            The correct answer or any incorrect answers can each branch off to a different question or submit the
            assessment.
          </p>
          <hr />
          <div class="branches">
            <div class="row top">
              <div class="left">
                <h6>Correct Answer</h6>
                <p>If the user answers correctly, they will follow this branch.</p>
              </div>
              <div class="right">
                <p class="left smallText">Goes to</p>
                <div class="right">
                  <h6>Logic</h6>
                  <div class="multiselect">
                    <Multiselect
                      v-model="question.correctBranch"
                      :options="getBranchingOptions(question.order)"
                      track-by="order"
                      :custom-label="customLabel"
                      placeholder="Select branching logic"
                      :show-labels="false"
                    >
                    </Multiselect>
                  </div>
                  <p class="smallText">Select which question this answer branches off to.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="left">
                <h6>Incorrect Answer</h6>
                <p>If the user answers incorrectly, they will follow this branch.</p>
              </div>
              <div class="right">
                <p class="left smallText">Goes to</p>
                <div class="right">
                  <h6>Logic</h6>
                  <div class="multiselect">
                    <Multiselect
                      v-model="question.incorrectBranch"
                      :options="getBranchingOptions(question.order)"
                      :custom-label="customLabel"
                      placeholder="Select branching logic"
                      :show-labels="false"
                    >
                    </Multiselect>
                  </div>
                  <p class="smallText">Select which question this answer branches off to.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CreateAssessmentSection>
      <button :disabled="disable" @click="submitAssessment">Save</button>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import CreateAssessmentSection from '@/components/assessment-creator/CreateAssessmentSection.vue';
import ArrowIcon from '@/assets/icons/arrow.svg';
import StandardAssessmentIcon from '@/assets/icons/standard_assessment.svg';
import BranchedAssessmentIcon from '@/assets/icons/branched_assessment.svg';
import CheckboxInactive from '@/assets/icons/checkbox_inactive.svg';
import CheckboxActive from '@/assets/icons/checkbox_active.svg';
import InfoIcon from '@/assets/icons/annotation_icon.svg';
import PlusIcon from '@/assets/icons/plus_icon.svg';
// import SearchIcon from '@/assets/icons/search_icon.svg';
import XIcon from '@/assets/icons/x_icon.svg';
import Dropzone from '@/components/Dropzone.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import AssessmentAnswer from '../../components/assessment-creator/AssessmentAnswer.vue';

const mediaTypes = ['Image', 'Video', 'Audio Clip'];

const assessmentSettings = [
  {
    id: 'randomizeQuestionOrder',
    text: 'Randomized question order',
    checked: false,
    tooltip: 'When checked, the ordering of questions in the assessment will be randomized.',
  },
  {
    id: 'randomizeAnswerOrder',
    text: 'Randomized answer order',
    checked: false,
    tooltip: 'When checked, the ordering of answers in questions will be randomized.',
  },
  {
    id: 'overallTimeLimit',
    text: 'Overall time limit',
    checked: false,
    tooltip:
      'When checked, admins can set an overall time limit for the assessment. Users will need to complete the entire assessment before the overall timer runs out.',
  },
  {
    id: 'individualTimeLimits',
    text: 'Individual time limits',
    checked: false,
    tooltip:
      'When checked, admins can set an individual time limit for any or all questions in the assessment. Users will need to complete these questions before their individual timer runs out.',
  },
  {
    id: 'hideScore',
    text: 'Hide user score and feedback at end of assessment',
    checked: false,
    tooltip:
      'When checked, the user will not be able to see their score or feedback at the end of the assessment. They will only see that it has been completed.',
  },
  {
    id: 'allowRetakes',
    text: 'Allow Retakes',
    checked: false,
    tooltip: 'When checked, users will be able to retake assessment until they meet the passing percentage',
  },
  {
    id: 'customInstructions',
    text: 'Custom start screen instructions',
    checked: false,
    tooltip:
      'When checked, all instructional text on the start screen will be replaced by custom text added in the Details section below.',
  },
];

const assessmentDetails = [
  {
    id: 'title',
    title: 'Title*',
    value: '',
    description: 'Give this assessment a meaningful title.',
    hidden: false,
  },
  {
    id: 'assessmentId',
    title: 'Assessment ID',
    value: 'Assessment ID will be assigned upon saving.',
  },
  {
    id: 'associatedAsset',
    title: 'Associated Asset*',
    value: '',
    description: 'Select the asset this assessment is associated with.',
    hidden: true,
  },
  {
    id: 'overallTimeLimit',
    title: 'Overall Time Limit*',
    value: '',
    description: 'Set an overall time limit for this assessment.',
    hidden: true,
  },
  {
    id: 'passingPercentage',
    title: 'Passing Percentage',
    value: '80',
    description:
      'Set a passing percentage for this assessment. The default recommendation is 80%. Users will be able to retake the assessment until they meet the passing percentage.',
    hidden: false,
  },
  {
    id: 'customInstructions',
    title: 'Custom Start Screen Instructions*',
    value: '',
    description:
      'When checked, all instructional text on the start screen will be replaced by custom text added in the Details section below.',
    hidden: true,
  },
];

const assessmentQuestions = [
  {
    order: '1',
    type: '',
    individualTimeLimit: '1',
    mediaType: null,
    correctBranch: null,
    incorrectBranch: null,
  },
];

const questionTypes = [
  {
    TYPE_ID: 1,
    TYPE_NAME: 'Multiple Choice',
  },
  {
    TYPE_ID: 2,
    TYPE_NAME: 'Single Answer',
  },
  {
    TYPE_ID: 3,
    TYPE_NAME: 'Matching',
  },
  {
    TYPE_ID: 4,
    TYPE_NAME: 'Open Answer',
  },
  {
    TYPE_ID: 5,
    TYPE_NAME: 'Fill-in-the-Blank',
  },
  {
    TYPE_ID: 6,
    TYPE_NAME: 'Hotspot',
  },
  {
    TYPE_ID: 7,
    TYPE_NAME: 'Drag and Drop',
  },
];

export default {
  name: 'create-assessment',
  components: {
    AssessmentAnswer,
    CreateAssessmentSection,
    ArrowIcon,
    StandardAssessmentIcon,
    BranchedAssessmentIcon,
    CheckboxInactive,
    CheckboxActive,
    InfoIcon,
    PlusIcon,
    // SearchIcon,
    XIcon,
    Dropzone,
    Multiselect,
  },
  data() {
    return {
      type: null,
      mediaTypes,
      assessmentSettings,
      assessmentDetails,
      assessmentQuestions,
      questionTypes,
      editId: null,
      assetSearchOptions: [],
      invalidRemediationUrl: [],
    };
  },
  computed: {
    branchingQuestions() {
      return this.assessmentQuestions.filter(
        (question) => this.isBranchLeaf(question) || parseInt(question.order, 10) === 1,
      );
    },
    branchingOptions() {
      const branchingOptions = [];
      this.assessmentQuestions.forEach((question) => {
        branchingOptions.push(parseInt(question.order, 10));
      });
      branchingOptions.push('Submit assessment');
      return branchingOptions;
    },
    typeFilteredAssessmentSettings() {
      if (this.type === 'branching') {
        return [
          this.assessmentSettings[1],
          this.assessmentSettings[2],
          this.assessmentSettings[4],
          this.assessmentSettings[5],
          this.assessmentSettings[6],
        ];
      }
      return this.assessmentSettings;
    },
    disable() {
      return !(
        this.assessmentDetails[0].value &&
        (!this.assessmentSettings[2].checked || this.assessmentDetails[3].value) &&
        !this.invalidRemediationUrl.length
      );
    },
    getAssessmentCreator() {
      return this.$store.getters.assessmentCreator;
    },
  },
  methods: {
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
        evt.preventDefault();
      }
    },
    getBranch(branch) {
      if (branch === 'Submit assessment') return null;
      return branch;
    },
    getBranchingOptions(order) {
      const index = this.branchingOptions.findIndex((o) => o === parseInt(order, 10));
      return this.branchingOptions.slice(index + 1, this.branchingOptions.length);
    },
    isBranchLeaf(question) {
      let x = false;
      // eslint-disable-next-line consistent-return
      this.assessmentQuestions.forEach((q) => {
        if (q.correctBranch === parseInt(question.order, 10) || q.incorrectBranch === parseInt(question.order, 10)) {
          x = true;
        }
      });
      return x;
    },
    customLabel(order) {
      if (order === 'Submit assessment') return order;
      return `Go to question ${order}`;
    },
    getAssets() {
      const loader = this.$loading.show();
      return this.$store
        .dispatch('getAssessmentAssets')
        .then((res) => {
          this.assetSearchOptions = res.assets;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    getFileNameFromUrl(url) {
      if (url) {
        const splitUrl = url.split('/');
        return splitUrl[splitUrl.length - 1];
      }
      return '';
    },
    isValidUrl(url) {
      try {
        // eslint-disable-next-line no-new
        new URL(url);
      } catch (e) {
        return false;
      }
      return true;
    },
    hasInvalidRemediationURL(question) {
      return this.invalidRemediationUrl.includes(question.question_uuid || question.temp_uuid);
    },
    validateRemediationURL(question) {
      const uuid = question.question_uuid || question.temp_uuid;
      const index = this.invalidRemediationUrl.indexOf(uuid);
      const isValid =
        !question.remediationURL || question.remediationURL === '' || this.isValidUrl(question.remediationURL);
      if (isValid) {
        // If exists in invalidation array then remove
        if (index > -1) {
          this.invalidRemediationUrl.splice(index, 1);
        }
        // If invalid and does not exist in invalidation array then add
      } else if (index < 0) {
        this.invalidRemediationUrl.push(uuid);
      }
    },
    handleSettingToggle(setting) {
      if (setting.id === 'overallTimeLimit') {
        this.assessmentDetails[3].hidden = !this.assessmentDetails[3].hidden;
        this.assessmentSettings[3].checked = false;
        this.assessmentQuestions.forEach((question) => {
          // eslint-disable-next-line no-param-reassign
          question.individualTimeLimit = '1';
        });
      } else if (setting.id === 'individualTimeLimits') {
        this.assessmentSettings[2].checked = false;
        this.assessmentDetails[3].value = '';
        this.assessmentDetails[3].hidden = true;
      } else if (setting.id === 'customInstructions') {
        this.assessmentDetails[5].hidden = !this.assessmentDetails[5].hidden;
      }
    },
    addQuestion() {
      this.assessmentQuestions.push({
        order: `${this.assessmentQuestions.length + 1}`.toString(),
        temp_uuid: `tempid_${this.assessmentQuestions.length + 1}`,
        type: '',
        individualTimeLimit: '1',
        mediaType: null,
        correctBranch: null,
        incorrectBranch: null,
      });
    },
    changedQuestionType(event, questionIndex) {
      // reset all type-specific the properties on the question
      if (this.assessmentQuestions[questionIndex].prompt) {
        delete this.assessmentQuestions[questionIndex].prompt;
      }
      if (this.assessmentQuestions[questionIndex].answers) {
        delete this.assessmentQuestions[questionIndex].answers;
      }
      if (this.assessmentQuestions[questionIndex].matching) {
        delete this.assessmentQuestions[questionIndex].matching;
      }
      if (this.assessmentQuestions[questionIndex].blanks) {
        delete this.assessmentQuestions[questionIndex].blanks;
      }
      if (this.assessmentQuestions[questionIndex].openAnswer) {
        delete this.assessmentQuestions[questionIndex].openAnswer;
      }
      if (this.assessmentQuestions[questionIndex].image) {
        delete this.assessmentQuestions[questionIndex].image;
      }
      if (this.assessmentQuestions[questionIndex].x1percent) {
        delete this.assessmentQuestions[questionIndex].x1percent;
      }
      if (this.assessmentQuestions[questionIndex].y1percent) {
        delete this.assessmentQuestions[questionIndex].y1percent;
      }
      if (this.assessmentQuestions[questionIndex].x2percent) {
        delete this.assessmentQuestions[questionIndex].x2percent;
      }
      if (this.assessmentQuestions[questionIndex].y2percent) {
        delete this.assessmentQuestions[questionIndex].y2percent;
      }
      if (this.assessmentQuestions[questionIndex].draggableItems) {
        delete this.assessmentQuestions[questionIndex].draggableItems;
      }

      // Create empty type-specific properties on the question object depending on which type was selected
      if (event.TYPE_ID && (event.TYPE_ID === 1 || event.TYPE_ID === 2)) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'answers', [
          {
            text: '',
            id: '1',
            isCorrect: false,
          },
          {
            text: '',
            id: '2',
            isCorrect: false,
          },
        ]);
      } else if (event.TYPE_ID && event.TYPE_ID === 3) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'matching', [
          {
            value1: '',
            value2: '',
          },
          {
            value1: '',
            value2: '',
          },
        ]);
      } else if (event.TYPE_ID && event.TYPE_ID === 4) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'openAnswer', '');
      } else if (event.TYPE_ID && event.TYPE_ID === 5) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'blanks', [{ text: '' }]);
      } else if (event.TYPE_ID && event.TYPE_ID === 6) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'x1percent', null);
        this.$set(this.assessmentQuestions[questionIndex], 'y1percent', null);
        this.$set(this.assessmentQuestions[questionIndex], 'x2percent', null);
        this.$set(this.assessmentQuestions[questionIndex], 'y2percent', null);
      } else if (event.TYPE_ID && event.TYPE_ID === 7) {
        this.$set(this.assessmentQuestions[questionIndex], 'prompt', '');
        this.$set(this.assessmentQuestions[questionIndex], 'image', null);
        this.$set(this.assessmentQuestions[questionIndex], 'draggableItems', [
          {
            image: null,
            x: null,
            y: null,
            scaledX: null,
            scaledY: null,
          },
        ]);
      }
    },
    answerToggled(answerIndex, questionIndex) {
      if (this.assessmentQuestions[questionIndex].type.TYPE_ID === 2) {
        // if Single Answer, er need to uncheck all other answers
        this.assessmentQuestions[questionIndex].answers.forEach((answer) => {
          // eslint-disable-next-line no-param-reassign
          answer.isCorrect = false;
        });
      }
      this.assessmentQuestions[questionIndex].answers[answerIndex].isCorrect =
        !this.assessmentQuestions[questionIndex].answers[answerIndex].isCorrect;
    },
    removeAnswer(answerIndex, questionIndex) {
      this.assessmentQuestions[questionIndex].answers.splice(answerIndex, 1);
      for (let index = answerIndex; index < this.assessmentQuestions[questionIndex].answers.length; index += 1) {
        this.assessmentQuestions[questionIndex].answers[index].id = (
          parseInt(this.assessmentQuestions[questionIndex].answers[index].id, 10) - 1
        ).toString();
      }
    },
    removeQuestion(questionIndex) {
      this.assessmentQuestions.splice(questionIndex, 1);
    },
    addAnswer(questionIndex) {
      this.assessmentQuestions[questionIndex].answers.push({
        text: '',
        id: `${this.assessmentQuestions[questionIndex].answers.length + 1}`,
        isCorrect: false,
      });
    },
    addMatch(questionIndex) {
      this.assessmentQuestions[questionIndex].matching.push({
        value1: '',
        value2: '',
      });
    },
    addDraggableItem(questionIndex) {
      this.assessmentQuestions[questionIndex].draggableItems.push({
        image: null,
        x: null,
        y: null,
        scaledX: null,
        scaledY: null,
      });
    },
    hotspotMouseUp(event, questionIndex) {
      if (!this.assessmentQuestions[questionIndex].x1percent && !this.assessmentQuestions[questionIndex].y1) {
        const canvasHeight = event.target.clientHeight;
        const canvasWidth = event.target.clientWidth;
        const userAnswerX = event.offsetX;
        const userAnswerY = event.offsetY;
        this.$set(this.assessmentQuestions[questionIndex], 'x1percent', (userAnswerX / canvasWidth) * 100);
        this.$set(this.assessmentQuestions[questionIndex], 'y1percent', (userAnswerY / canvasHeight) * 100);
      }
    },
    hotspotMouseDown(event, questionIndex) {
      if (!this.assessmentQuestions[questionIndex].x2percent && !this.assessmentQuestions[questionIndex].y2percent) {
        const canvasHeight = event.target.clientHeight;
        const canvasWidth = event.target.clientWidth;
        const userAnswerX = event.offsetX;
        const userAnswerY = event.offsetY;
        this.$set(this.assessmentQuestions[questionIndex], 'x2percent', (userAnswerX / canvasWidth) * 100);
        this.$set(this.assessmentQuestions[questionIndex], 'y2percent', (userAnswerY / canvasHeight) * 100);
      }
    },
    imageAccepted(params, questionIndex) {
      this.$set(this.assessmentQuestions[questionIndex], 'image', params.file);

      // shrink the size of the canvas if needed
      const canvas = document.getElementById(`canvas-${questionIndex}`);
      if (
        canvas &&
        canvas.offsetWidth &&
        params &&
        params.file &&
        params.file.width &&
        canvas.offsetWidth > params.file.width
      ) {
        canvas.style.width = `${params.file.width}px`;
        canvas.style.height = `${params.file.height}px`;
      }

      if (params && params.file && params.file.name) {
        this.$store.dispatch('postMedia', params.file.name).then((res) => {
          this.assessmentQuestions[questionIndex].mediaUrl = res.data.mediaUrl;
          this.$store.dispatch('putFile', { file: params.file, url: res.data.presignedUrl });
        });
      }

      setTimeout(() => {
        this.getCanvasScales();
      }, 1000);
    },
    getCanvasScales() {
      this.assessmentQuestions.forEach((question, questionIndex) => {
        if (question.type.TYPE_ID === 7) {
          const image = document.getElementById(`image-${questionIndex}`);
          const scale = image.naturalWidth / image.width;
          this.$set(this.assessmentQuestions[questionIndex], 'canvasScaler', scale);
        }
      });
    },
    draggableImageAccepted(params, questionIndex, draggableItemIndex) {
      this.$set(this.assessmentQuestions[questionIndex].draggableItems[draggableItemIndex], 'image', params.file);
      this.$store.dispatch('postMedia', params.file.name).then((res) => {
        this.assessmentQuestions[questionIndex].draggableItems[draggableItemIndex].mediaUrl = res.data.mediaUrl;
        this.$store.dispatch('putFile', { file: params.file, url: res.data.presignedUrl });
      });
    },
    clearImage(questionIndex) {
      this.assessmentQuestions[questionIndex].image = null;
      this.assessmentQuestions[questionIndex].x1percent = '';
      this.assessmentQuestions[questionIndex].y1percent = '';
      this.assessmentQuestions[questionIndex].x2percent = '';
      this.assessmentQuestions[questionIndex].y2percent = '';
      this.assessmentQuestions[questionIndex].mediaUrl = '';
    },
    clearDraggableImage(questionIndex, draggableItemIndex) {
      this.assessmentQuestions[questionIndex].draggableItems[draggableItemIndex].image = null;
      this.assessmentQuestions[questionIndex].draggableItems[draggableItemIndex].mediaUrl = null;
    },
    submitAssessment() {
      const questions = [];
      this.assessmentQuestions.forEach((question, index) => {
        let answerMetadata;
        if (question.answers) answerMetadata = { answers: question.answers };
        else if (question.matching) answerMetadata = { matching: question.matching };
        else if (question.openAnswer) answerMetadata = { openAnswer: question.openAnswer };
        else if (question.blanks) answerMetadata = { blanks: question.blanks };
        else if (question.x1percent && question.x2percent && question.y1percent && question.y2percent) {
          answerMetadata = {
            x1percent: question.x1percent,
            x2percent: question.x2percent,
            y1percent: question.y1percent,
            y2percent: question.y2percent,
          };
        } else if (question.draggableItems) {
          const items = [];
          question.draggableItems.forEach((item) => {
            items.push({
              mediaUrl: item.mediaUrl,
              scaledX: item.scaledX,
              scaledY: item.scaledY,
              x: item.x,
              y: item.y,
            });
          });
          answerMetadata = {
            draggableItems: items,
          };
        }
        questions.push({
          questionType: question.type.TYPE_ID,
          timeLimit: question.individualTimeLimit || null,
          questionText: question.prompt,
          mediaType: question.mediaType,
          order: parseInt(question.order, 10),
          mediaUrl: question.mediaUrl,
          answerMetadata,
          question_uuid: question.question_uuid,
          remediationText: question.remediationText || null,
          remediationURLTitle: question.remediationURLTitle || null,
          remediationURL: question.remediationURL || null,
          isRoot: !!(this.type === 'branching' && index === 0),
          correctBranch: this.getBranch(question.correctBranch),
          incorrectBranch: this.getBranch(question.incorrectBranch),
        });
        if (!this.assessmentSettings[3].checked) questions[questions.length - 1].timeLimit = null;
      });

      if (!this.editId) {
        const data = {
          assessment: {
            randomQuestions: this.assessmentSettings[0].checked,
            randomAnswers: this.assessmentSettings[1].checked,
            overallTimeLimit: this.assessmentSettings[2].checked,
            individualTimeLimit: this.assessmentSettings[3].checked,
            hideUserScore: this.assessmentSettings[4].checked,
            allowRetakes: this.assessmentSettings[5].checked,
            title: this.assessmentDetails[0].value,
            associatedAssetID: this.assessmentDetails[2].value || null,
            timeLimit: this.assessmentDetails[3].value,
            passingPercentage: this.assessmentDetails[4].value,
            isBranched: !!(this.type === 'branching'),
            customInstructions: this.assessmentDetails[5].value,
          },
          questions,
        };
        const loader = this.$loading.show();
        this.$store
          .dispatch('submitAssessment', data)
          .then((res) => {
            this.$router.push(`/assessment-creator/success/${res.data.uuid}`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            loader.hide();
          });
      } else {
        const data = {
          assessment: {
            assessmentId: this.editId,
            randomQuestions: this.assessmentSettings[0].checked,
            randomAnswers: this.assessmentSettings[1].checked,
            overallTimeLimit: this.assessmentSettings[2].checked,
            individualTimeLimit: this.assessmentSettings[3].checked,
            hideUserScore: this.assessmentSettings[4].checked,
            allowRetakes: this.assessmentSettings[5].checked,
            title: this.assessmentDetails[0].value,
            associatedAssetID: this.assessmentDetails[2].value || null,
            timeLimit: this.assessmentDetails[3].value,
            passingPercentage: this.assessmentDetails[4].value,
            isBranched: !!(this.type === 'branching'),
            customInstructions: this.assessmentDetails[5].value,
          },
          questions,
        };
        const loader = this.$loading.show();
        this.$store
          .dispatch('editAssessment', data)
          .then(() => {
            this.$router.push(`/assessment-creator/success/${this.editId}`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    getAssessmentBeingEdited() {
      const loader = this.$loading.show();
      this.$store
        .dispatch('getAssessment', this.editId)
        .then((res) => {
          if (res.isBranched) this.type = 'branching';

          this.assessmentDetails[0].value = res.assessment_title;
          this.assessmentDetails[1].value = res.assessment_uuid;
          this.assessmentDetails[3].value = res.time_limit;
          this.assessmentDetails[4].value = res.passingPercentage;
          this.assessmentDetails[5].value = res.customInstructions;

          this.assessmentSettings[0].checked = res.random_questions === 1;
          this.assessmentSettings[1].checked = res.random_answers === 1;
          this.assessmentSettings[2].checked = res.overall_time_limit === 1;
          if (res.overall_time_limit === 1) this.handleSettingToggle({ id: 'overallTimeLimit' });
          this.assessmentSettings[3].checked = res.individual_time_limit === 1;
          if (res.individual_time_limit === 1) this.handleSettingToggle({ id: 'individualTimeLimits' });
          this.assessmentSettings[4].checked = res.hide_user_score === 1;
          this.assessmentSettings[5].checked = res.allowRetakes === 1;
          this.assessmentSettings[6].checked = res.customInstructions;
          if (res.customInstructions) this.handleSettingToggle({ id: 'customInstructions' });

          if (res.overall_time_limit === 1) this.assessmentDetails[3].hidden = false;

          if (res.associated_asset_id) {
            this.assessmentDetails[2].value = res.associated_asset_id;
          }

          if (res.questions && res.questions.length) {
            const questions = [];
            res.questions.forEach((question, index) => {
              // Create default object for questions keys that are the same
              let questionObject = {
                order: (index + 1).toString(),
                individualTimeLimit: '1',
                type: this.questionTypes[question.question_type - 1],
                prompt: question.question_text,
                mediaType: question.media_type,
                mediaUrl: question.media_url,
                question_uuid: question.question_uuid,
                remediationText: question.remediationText,
                remediationURL: question.remediationURL,
                remediationURLTitle: question.remediationURLTitle,
                correctBranch: parseInt(question.correctBranch, 10) || null,
                incorrectBranch: parseInt(question.incorrectBranch, 10) || null,
              };
              // Spread and update keys based on individual type
              if (question.question_type === 1 || question.question_type === 2) {
                questionObject = {
                  ...questionObject,
                  individualTimeLimit: question.question_time_limit,
                  answers: question.answer_metadata.answers,
                };
              } else if (question.question_type === 3) {
                questionObject = {
                  ...questionObject,
                  matching: question.answer_metadata.matching,
                };
              } else if (question.question_type === 4) {
                questionObject = {
                  ...questionObject,
                  openAnswer: question.answer_metadata.openAnswer,
                };
              } else if (question.question_type === 5) {
                questionObject = {
                  ...questionObject,
                  blanks: question.answer_metadata.blanks,
                };
              } else if (question.question_type === 6) {
                questionObject = {
                  ...questionObject,
                  x1percent: question.answer_metadata.x1percent,
                  x2percent: question.answer_metadata.x2percent,
                  y1percent: question.answer_metadata.y1percent,
                  y2percent: question.answer_metadata.y2percent,
                };
              } else if (question.question_type === 7) {
                questionObject = {
                  ...questionObject,
                  draggableItems: question.answer_metadata.draggableItems || [],
                  image: question.image,
                  canvasScaler: '',
                };
              }
              // Push updated object to questions array
              questions.push(questionObject);
            });
            this.assessmentQuestions = questions;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAssessmentBeingDuplicated(id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch('getAssessment', id)
        .then((res) => {
          if (res.isBranched) this.type = 'branching';

          this.assessmentDetails[0].value = '';
          this.assessmentDetails[3].value = res.time_limit;
          this.assessmentDetails[4].value = res.passingPercentage || assessmentDetails[4].value;
          this.assessmentDetails[5].value = res.customInstructions || assessmentDetails[5].value;

          this.assessmentSettings[0].checked = res.random_questions === 1;
          this.assessmentSettings[1].checked = res.random_answers === 1;
          this.assessmentSettings[2].checked = res.overall_time_limit === 1;
          this.assessmentSettings[3].checked = res.individual_time_limit === 1;
          this.assessmentSettings[4].checked = res.hide_user_score === 1;
          this.assessmentSettings[5].checked = res.allowRetakes === 1;
          this.assessmentSettings[6].checked = res.customInstructions;
          if (res.customInstructions) this.handleSettingToggle({ id: 'customInstructions' });

          if (res.overall_time_limit === 1) this.assessmentDetails[3].hidden = false;

          if (res.questions && res.questions.length) {
            const questions = [];
            res.questions.forEach((question, index) => {
              // Create default object for questions keys that are the same
              let questionObject = {
                order: (index + 1).toString(),
                individualTimeLimit: '1',
                type: this.questionTypes[question.question_type - 1],
                prompt: question.question_text,
                mediaType: question.media_type,
                mediaUrl: question.media_url,
                question_uuid: question.question_uuid,
                // TODO put in proper key names once they are implemented
                remediationText: question.remediationText,
                remediationURL: question.remediationURL,
                remediationURLTitle: question.remediationURLTitle,
                correctBranch: parseInt(question.correctBranch, 10) || null,
                incorrectBranch: parseInt(question.incorrectBranch, 10) || null,
              };

              // Spread and update keys based on individual type
              if (question.question_type === 1 || question.question_type === 2) {
                questionObject = {
                  ...questionObject,
                  individualTimeLimit: question.timeLimit,
                  answers: question.answer_metadata.answers,
                };
              } else if (question.question_type === 3) {
                questionObject = {
                  ...questionObject,
                  matching: question.answer_metadata.matching,
                };
              } else if (question.question_type === 4) {
                questionObject = {
                  ...questionObject,
                  openAnswer: question.answer_metadata.openAnswer,
                };
              } else if (question.question_type === 5) {
                questionObject = {
                  ...questionObject,
                  blanks: question.answer_metadata.blanks,
                };
              } else if (question.question_type === 6) {
                questionObject = {
                  ...questionObject,
                  x1percent: question.answer_metadata.x1percent, // todo: scale these down
                  x2percent: question.answer_metadata.x2percent,
                  y1percent: question.answer_metadata.y1percent,
                  y2percent: question.answer_metadata.y2percent,
                };
              } else if (question.question_type === 7) {
                questionObject = {
                  ...questionObject,
                  draggableItems: question.answer_metadata.draggableItems || [],
                };
              }
              // Push updated object to questions array
              questions.push(questionObject);
            });
            this.assessmentQuestions = questions;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.getCanvasScales();
          }, 1000);
          loader.hide();
        });
    },
    dragStart(event, draggableItem, draggableItemIndex) {
      const itemIndexes = event.target.id.split('-');
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'move';
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('item', draggableItem);
      event.dataTransfer.setData('questionIndex', itemIndexes[0]);
      event.dataTransfer.setData('itemIndex', itemIndexes[1]);
      event.dataTransfer.setData('grabbedX', event.offsetX);
      event.dataTransfer.setData('grabbedY', event.offsetY);
      event.dataTransfer.setData('draggableItemIndex', draggableItemIndex);
    },
    dragEnd(event) {
      event.preventDefault();
      const questionIndex = event.dataTransfer.getData('questionIndex');
      const itemIndex = event.dataTransfer.getData('itemIndex');
      const grabbedX = event.dataTransfer.getData('grabbedX');
      const grabbedY = event.dataTransfer.getData('grabbedY');
      const splitCanvas = event.target.id.split('-');
      const canvasQuestionIndex = splitCanvas[1];

      if (canvasQuestionIndex === questionIndex) {
        this.assessmentQuestions[questionIndex].draggableItems[itemIndex].x = Math.floor(event.offsetX - grabbedX);
        this.assessmentQuestions[questionIndex].draggableItems[itemIndex].y = Math.floor(event.offsetY - grabbedY);
        this.assessmentQuestions[questionIndex].draggableItems[itemIndex].scaledX = Math.floor(
          (event.offsetX - grabbedX) * this.assessmentQuestions[questionIndex].canvasScaler,
        );
        this.assessmentQuestions[questionIndex].draggableItems[itemIndex].scaledY = Math.floor(
          (event.offsetY - grabbedY) * this.assessmentQuestions[questionIndex].canvasScaler,
        );
      }
    },
    getSrc(question) {
      if (question) {
        if (question.image && question.image.dataURL) return question.image.dataURL;
        if (question.mediaUrl) return question.mediaUrl;
      }
      return '';
    },
  },
  created() {
    // const loader = this.$loading.show();
    // this.getAssets()
    //   .then(() => {
    // if we're editing an existing assessment
    window.addEventListener('resize', this.getCanvasScales);
    if (this.$route.name === 'edit-assessment' && this.$route.params.id) {
      this.editId = this.$route.params.id;
      this.getAssessmentBeingEdited();
    } else if (this.$route.name === 'create-assessment' && this.$route.params.id) {
      // if we're duplucating an existing assessment
      this.getAssessmentBeingDuplicated(this.$route.params.id);
    } else {
      // if we're creating a new assessment from scratch
      this.type = null;
      this.assessmentSettings = [
        {
          id: 'randomizeQuestionOrder',
          text: 'Randomized question order',
          checked: false,
          tooltip: 'When checked, the ordering of questions in the assessment will be randomized.',
        },
        {
          id: 'randomizeAnswerOrder',
          text: 'Randomized answer order',
          checked: false,
          tooltip: 'When checked, the ordering of answers in questions will be randomized.',
        },
        {
          id: 'overallTimeLimit',
          text: 'Overall time limit',
          checked: false,
          tooltip:
            'When checked, admins can set an overall time limit for the assessment. Users will need to complete the entire assessment before the overall timer runs out.',
        },
        {
          id: 'individualTimeLimits',
          text: 'Individual time limits',
          checked: false,
          tooltip:
            'When checked, admins can set an individual time limit for any or all questions in the assessment. Users will need to complete these questions before their individual timer runs out.',
        },
        {
          id: 'hideScore',
          text: 'Hide user score and feedback at end of assessment',
          checked: false,
          tooltip:
            'When checked, the user will not be able to see their score or feedback at the end of the assessment. They will only see that it has been completed.',
        },
        {
          id: 'allowRetakes',
          text: 'Allow Retakes',
          checked: false,
          tooltip: 'When checked, users will be able to retake assessment until they meet the passing percentage',
        },
        {
          id: 'customInstructions',
          text: 'Custom start screen instructions',
          checked: false,
          tooltip:
            'When checked, all instructional text on the start screen will be replaced by custom text added in the Details section below.',
        },
      ];

      this.assessmentDetails = [
        {
          id: 'title',
          title: 'Title*',
          value: '',
          description: 'Give this assessment a meaningful title.',
          hidden: false,
        },
        {
          id: 'assessmentId',
          title: 'Assessment ID',
          value: 'Assessment ID will be assigned upon saving.',
        },
        {
          id: 'associatedAsset',
          title: 'Associated Asset*',
          name: '',
          value: '',
          description: 'Select the asset this assessment is associated with.',
          hidden: true,
        },
        {
          id: 'overallTimeLimit',
          title: 'Overall Time Limit*',
          value: '',
          description: 'Set an overall time limit for this assessment.',
          hidden: true,
        },
        {
          id: 'passingPercentage',
          title: 'Passing Percentage',
          value: '80',
          description:
            'Set a passing percentage for this assessment. The default recommendation is 80%. If enabled, users will be able to retake the assessment until they meet the passing percentage.',
          hidden: false,
        },
        {
          id: 'customInstructions',
          title: 'Custom Start Screen Instructions*',
          value: '',
          description:
            'When checked, all instructional text on the start screen will be replaced by custom text added in the Details section below.',
          hidden: true,
        },
      ];
      this.assessmentQuestions = [
        {
          order: '1',
          type: '',
          individualTimeLimit: '1',
          mediaType: null,
          correctBranch: null,
          incorrectBranch: null,
        },
      ];
    }
    // })
    // .finally(() => {
    //   loader.hide();
    // });
  },
  destroyed() {
    window.removeEventListener('resize', this.getCanvasScales);
  },
};
</script>

<style lang="scss" scoped>
.create-assessment {
  padding: 35px 75px;
  .type-select {
    max-width: 700px;
    margin: 0 auto;
    h3 {
      margin: 40px 0 35px 0;
      font-family: 'VWHead Light', $fallback-font-stack;
      font-size: 20px;
      font-weight: 300;
    }
    .types {
      display: flex;
      gap: 50px;
      > div {
        flex: 1;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba($primary-black, 0.16);
        svg {
          display: block;
          height: 80px;
          margin: 20px auto 25px auto;
        }
        hr {
          width: 90%;
          background: $light-grey;
        }
        .label {
          display: flex;
          gap: 8px;
          align-items: center;
          margin: 10px 0 0 0;
          h6 {
            flex: 1;
            margin: 0;
            font-family: 'VWHead Bold', $fallback-font-stack;
            font-size: 14px;
            color: $primary-light;
            text-align: right;
          }
          svg {
            height: 8px;
            padding-top: 2px;
            margin: 0;
          }
        }
      }
    }
  }
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0 10px 15px;
    &.smaller {
      max-width: 300px;
      margin-right: 15px;
    }
  }
}
.fill-in-the-blank {
  .description {
    margin-bottom: 20px;
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
    span {
      font-family: 'VWText Bold', $fallback-font-stack;
      font-size: 14px;
      color: $warning-red;
    }
  }
}
.description {
  display: block;
  margin-top: 5px;
  font-family: 'VWText Light', $fallback-font-stack;
  font-size: 10px;
}
.title {
  margin: 0 0 5px 0;
  font-family: 'VWHead Bold', $fallback-font-stack;
  font-size: 10px;
}

.setting {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background: $light-grey;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  span {
    margin-right: 10px;
    font-family: 'VWText Light', $fallback-font-stack;
    font-size: 14px;
  }
  .info {
    width: 16px;
    height: 16px;
    font-family: 'VWHead Bold', $fallback-font-stack;
    text {
      font-family: inherit;
    }
  }
}

.detail {
  margin-bottom: 15px;
  .minutes {
    font-family: 'VWHead Light', $fallback-font-stack;
    font-size: 12px;
  }
  .details-text {
    font-size: 14px;
    opacity: 0.5;
  }
}

.question {
  display: flex;
  padding: 30px;
  margin-bottom: 30px;
  border: 2px dashed $light-grey;
  .order {
    margin-right: 15px;
    input {
      width: 36px;
      padding: 10px 0;
      text-align: center;
    }
  }
  .questions-content {
    flex: 1;
    .type {
      flex: 1;
      margin-bottom: 15px;
    }
    .individual-time-limit {
      margin-bottom: 15px;
      .minutes {
        font-family: 'VWHead Light', $fallback-font-stack;
        font-size: 12px;
      }
    }
    .media {
      display: flex;
      gap: 20px;
      h6 {
        margin: 20px 0 5px 0;
        font-family: 'VWHead Bold', $fallback-font-stack;
        font-size: 10px;
      }
      .type {
        flex: 1;
        max-width: calc((100% - 20px) / 2);
      }
      .image-attach {
        flex: 1;
        margin-bottom: 15px;
        form {
          display: flex;
          align-items: center;
          min-height: 40px;
        }
        img {
          max-width: 100%;
          height: auto;
        }
        .remove {
          margin-bottom: 20px;
          font-family: 'VWText Bold', $fallback-font-stack;
          font-size: 14px;
          color: $warning-red;
          cursor: pointer;
          svg {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            margin-left: auto;
          }
        }
        ::v-deep {
          .current {
            img {
              max-width: 120px;
              height: auto;
            }
          }
        }
      }
    }
    .prompt {
      margin-bottom: 15px;
    }
    .answers {
      margin-bottom: 15px;
    }
    .matching {
      margin-bottom: 15px;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &.top {
          margin-bottom: 20px;
        }
        .left {
          margin-right: 15px;
        }
      }
    }
    .open-answer {
      margin-bottom: 15px;
    }
    .builder {
      margin-bottom: 15px;
      .builder-inner {
        position: relative;
        width: fit-content;
        max-width: 100%;
        height: auto;
        &.grey {
          width: 100%;
          min-height: 300px;
          background: $light-grey;
        }
        img,
        .canvas,
        .builder-overlay {
          top: 0;
          left: 0;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-user-drag: none;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .canvas {
          position: absolute;
          z-index: 2;
          width: 100%;
          &.fullHeight {
            height: 100%;
          }
        }
        .builder-overlay {
          position: absolute;
          z-index: 3;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: calc(100% - 4px);
          .center-row {
            display: flex;
          }
          .overlay-piece {
            background: #00000050;
            // width: 100px;
            // height: 100px;
          }
          .hotspot {
            display: inline-block;
            border: 1px solid $primary-light;
          }
        }
        .canvas-item {
          position: absolute;
          z-index: 5;
          transform-origin: left top;
          &.hidden {
            display: none;
          }
        }
      }
      .draggable-items {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        // height: 110px;
        padding: 5px 5px 0 5px;
        margin: 15px 0;
        border: 1px dashed $light-grey;
        &.empty {
          height: 110px;
        }
        img {
          // width: 100px;
          // height: 100px;
          // padding: 5px;
          max-width: 100%;
        }
      }
      .draggable-options {
        margin: 15px 0;
        .option {
          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              flex: 2;
              justify-content: space-between;
              margin-right: 100px;
              .dropzone {
                width: 100%;
              }
            }
            .right {
              display: flex;
              flex: 1;
              align-items: center;
              input {
                width: 100px;
                &.left {
                  margin-right: 0;
                  margin-left: auto;
                }
              }
              span {
                margin: 0 5px;
              }
            }
          }
        }
        h6 {
          margin: 15px 0;
          font-family: 'VWHead Bold', $fallback-font-stack;
          font-size: 10px;
        }
      }
    }
    .bottom-row {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .left {
        display: flex;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          cursor: pointer;
        }
        span {
          font-family: 'VWText Bold', $fallback-font-stack;
          font-size: 14px;
          color: $primary-light;
          cursor: pointer;
        }
      }
      .right {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
        span {
          font-family: 'VWText Bold', $fallback-font-stack;
          font-size: 14px;
          color: $warning-red;
        }
      }
    }
  }
}

.branching-question {
  padding: 30px;
  margin-bottom: 30px;
  border: 2px dashed $light-grey;
  h6 {
    margin: 0 0 8px 0;
    font-family: 'VWHead Bold', $fallback-font-stack;
    font-size: 10px;
  }
  p {
    margin: 0 0 20px 0;
    font-family: 'VWText Light', $fallback-font-stack;
    font-size: 14px;
    &.smallText {
      font-family: 'VWText Light', $fallback-font-stack;
      font-size: 10px;
    }
  }
  hr {
    background: $light-grey;
  }
  .branches {
    margin-top: 15px;
    .row {
      display: flex;
      gap: 40px;
      > .left {
        flex: 1;
      }
      > .right {
        display: flex;
        flex: 1;
        gap: 30px;
        align-items: center;
      }
    }
  }
}

.remediation {
  margin-top: 20px;
  &_input {
    padding: 10px 15px;
    margin-top: 10px;

    &.error {
      border-color: $red;
    }
  }

  .error-message {
    font-size: 0.7rem;
    font-weight: bold;
    color: $red;
  }
}
.link {
  font-family: 'VWText Bold', $fallback-font-stack;
  color: $primary-light;
  text-decoration: none;
}

.add-question,
.add-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  span {
    font-family: 'VWText Bold', $fallback-font-stack;
    font-size: 14px;
    color: $primary-light;
  }
}
.add-option {
  margin-top: 10px;
}

::v-deep .multiselect {
  margin-top: 5px;

  .flex-container {
    display: flex;
    align-items: center;

    .color-box {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      border-radius: 3px;
    }

    .image-box {
      min-width: 100px;
      min-height: 56px;
      background: url('../../assets/images/default_asset_thumbnail.png?external');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .info-box {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      font-size: 14px;

      span {
        padding-bottom: 3px;
        font-family: 'VWHead Bold', $fallback-font-stack;
        color: #000;
        letter-spacing: 0;
        white-space: pre-wrap;
      }

      a {
        font-family: 'VWHead Light', $fallback-font-stack;
        color: #00b0f0;
      }
    }
  }

  .title-box {
    font-family: 'VWText Light', $fallback-font-stack;
    font-size: 14px;

    &.placeholder-box {
      color: $primary-black;
      opacity: 50%;
    }
  }

  &.assetSearch {
    .multiselect__single {
      padding-left: 3px;
      margin-bottom: 10px;
    }
    .multiselect__select {
      display: none;
    }

    .multiselect__tags {
      padding: 10px 40px 0 8px;
    }

    .multiselect__placeholder {
      padding-left: 8px;
      opacity: 1;
      .title-box {
        display: inline-flex;
        align-items: center;
      }
      .placeholder-box {
        opacity: 0.5;
      }
      svg {
        width: 18.44px;
        height: 18.44px;
        margin-right: 15px;
      }
    }
  }

  &.disabled {
    .multiselect__tags,
    .multiselect__select {
      background-color: #dfe4e880;
    }

    .multiselect__placeholder {
      .placeholder-box {
        opacity: 1;
      }
    }
  }
}
::v-deep {
  .v-popper--theme-tooltip .v-popper__inner {
    padding: 5px 10px 4px;
    color: white;
    background: red !important;
    border-radius: 16px;
  }

  .dropzone {
    .dropzone-title {
      top: 6px !important;
    }
    img {
      max-width: 100px;
    }
  }
}
.blank-input {
  display: flex;
  input {
    flex: auto;
  }
}
.rules {
  font-size: 10px;
}
</style>
